import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const ReasonsDeepCondition = () => (
  <Layout>
    <SEO
      title="5 Reasons Why You Should Deep Condition"
      description="One of the things that still surprises me is the amount of people that are struggling with natural hair care that have never tried a deep condition. Try it now with these tips"
    />
    <h1>5 Reasons Why You Should Deep Condition</h1>
    <h2 style={{color:"Orchid"}}>Get the healthy hair you've been dreaming of with deep conditioning</h2>
    <p>One of the things that still surprises me is the amount of people that are struggling with natural hair care that have never tried a deep condition. Lots of you are aware of it and have a good idea of its benefits but for some reason, have just never tried.</p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/8h3AY8J.jpg"
        alt="how to get an afro nahc"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@timmarshall?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Tim Marshall</a></p>
    </div>
    <p>It might be because the benefits don’t seem good enough for the amount of time you have to commit to it. Or, following on from that, the amount of time you have to commit might be far too long for someone with a reasonably busy life. Deep conditioning as a process can easily take over an hour and you have to be pretty committed to go through that.</p>
    <p>About the benefits not being good enough, that’s why I’m here to try to convince you to give it a try and see what you can get out of it. I can say that everyone who I’ve managed to convince have seen benefits either immediately in the short term or in the long run.</p>
    <p>Let’s cut to the chase and get into the reasons you came for…</p>

    <h2>1. Regain lost nutrients</h2>
    <p>Deep conditioning helps you regain lost nutrients to improve the health of your hair. The natural elements like wind, heat from the sun, freezing cold etc. all contribute to stripping your hair of its natural protection and then its nutrients. Then you have the general wear and tear you get from rough detangling and the odd occasion when you put something on your hair that isn’t silk or satin. A deep conditioner replaces these nutrients and can give life to your hair.</p>

    <h2>2. Less conditioner needed later on</h2>
    <p>When you deep condition properly, the hydration and nutritional benefits are long lasting. Where a normal conditioner or leave-in conditioner is meant to give temporary benefits that you have to top up daily, a leave in conditioner has the opposite effect. A good leave in condition routine can easily last a week or longer.</p>
    <p>The benefit of this is that you can get away with using far less of your other products, simply because you don’t need to. With less product build-up in your hair, you can even go longer without washing your hair. One tip I suggest is that if you are planning a long term protective style like braids, have a really good deep condition before you do to help your hair stay healthy.</p>

    <h2>3. Relaxation time for your mental health</h2>
    <p>One often overlooked benefit, because it might not seem that important is the relaxation you get from a deep condition. The whole process can easily be the most relaxing part of doing your hair if you want it to be. Combine it with a hot bath and now you can justify staying in there for so long without feeling guilty.</p> 
    <p>It might not even be a bath, you can sit under the steamer to help it all sink in and have a chat with your friends or read a book. Whatever you choose, it can be a whole hour to yourself where you can have that time to relax and look after your mental health. There’s the added benefit of having great hair when you’re done too.</p>

    <h2>4. Lots of options</h2>
    <p>Just like with oils and conditioners, there are lots of options to choose from which could be a good or bad thing depending on how you are with choice. When I say there are lots of options, I mean that there is something out there for everyone.</p>
    <p>You can go homemade (as we will get onto) but don’t think you have to make anything yourself to get great results. There are products out there that use similar ingredients you can find in conditioners but with a focus on being nutrient rich for damage repair and strengthening. There are also products that contain all natural ingredients so if that’s your aim you can have it.</p>

    <h2>5. Homemade</h2>
    <p>Making your own deep conditioner is something you should seriously consider. There are so many ingredients that contain all the essential vitamins & minerals that if you don’t like the way one ingredient interacts with your hair, you can find something else that does the same thing yet agrees with your hair. Your priorities could be smell, texture, colour, weight and you can get a balance of these things depending on what ingredients you go with.</p>
    <p>You don’t have to start from scratch either. Something I recommend is to take your favourite leave in conditioner and oil and use them as a base to make your deep conditioner. You can find high protein natural ingredients and blend them in along with any other nutrients you want to add into the mix. Use your imagination to find something that works.</p>

    <h2>How to get started</h2>
    <p>Deep conditioning your hair is one of the things that most people can get away without doing. If you are one of the lucky ones then congratulations. I suspect that if you are reading this then it’s something you want to at least try. If you are looking for a way to make your hair healthy and all the other things you’ve tried aren’t working then deep conditioning could be the thing you need.</p>
    <p>There are a few ways to get into trying it as I’ve mentioned. If you really don’t want to do it yourself but want all the benefits then try your local salon, if they are any good then they will have a deep condition as one of their services.</p>
    <p>Beyond that, and you are looking to save some money, find a friend or family member that has done it before and see if they will help you. If not then see if anyone is interested in helping you figure it all out. Believe me, it really isn’t that hard so if you a feeling up to the task then go for it alone, you’ll feel so proud of yourself afterwards.</p>
    <p>You will get hair that has a visible shine, it will be strong and healthy. It should have the protection it needs to grow longer because it won’t break anywhere near as much as it used to. And most importantly, with a bit of experimentation and practise, you will have a solution that makes you happy.</p>


    
    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>                  
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default ReasonsDeepCondition
